import React, { useState, useEffect } from "react";
import styles from "./ModalQuestionariosComponent.module.css";
import { Input, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';
import api from '../../service/api';
import { AiOutlineSearch, AiOutlineArrowRight } from 'react-icons/ai';
import ModalComponent from '../../components/Modal/ModalComponent';

function ModalQuestionariosComponent(props) {

    const [isOpen, setIsOpen] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const [questionarios, setQuestionarios] = useState([]);
    const [questionariosFiltrados, setQuestionariosFiltrados] = useState([]);
    const [busca, setBusca] = useState("");

    useEffect(() => {
        api.get('questionarios').then(({ data }) => {
            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.nome < obj2.nome ? -1 :
                    (obj1.nome > obj2.nome ? 1 : 0);
            });
            setQuestionarios(dataSort);
        });
    }, []);

    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = questionarios.filter((questionario) => questionario.nome.toLocaleLowerCase().includes(lowerBusca));
        setQuestionariosFiltrados(filtrados);
    }, [busca]);

    const handleCancel = () => {
        setIsOpen(false);
        window.location.reload();
    }

    const enviarQuestionario = (questionario) => {
        api.post('envioQuestionario', {
            idPaciente: props.paciente,
            questionario: questionario
        }).then((response) => {
            // let url = "https://api.whatsapp.com/send?phone=55" +
            //     parseInt(props.telefone.replace(/[^0-9]/g, '')) +
            //     "&text=Link para responder o questionário https://healthb4.com.br/perguntas/" +
            //     response.data.linkCript;

            const baseUrl = process.env.REACT_APP_FRONT_URL;

            let url = "https://api.whatsapp.com/send?phone=55" +
                parseInt(props.telefone.replace(/[^0-9]/g, '')) +
                `&text=Link para responder o questionário ${baseUrl}/perguntas/${response.data.linkCript}`;
            
            window.open(url);
            setIsSent(true);
        });
    }

    return (
        <div>
            {isSent ? <ModalComponent mensagem="Questionário enviado com sucesso!" icon="SucessoEnvioQuestionario" link="home" /> : null}
            <Modal
                centered
                size=""
                isOpen={isOpen}
            >
                <ModalHeader>
                    Escolha um Questionário
                </ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <Input size="sm" placeholder="Busque pelo nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                        <InputGroupText className={styles.textBuscar}>
                            <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                    </InputGroup>
                    <Table size="sm" striped className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    Questionário
                            </th>
                                <th>
                                    Ações
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td className={styles.tbody}>
                                    Padrão
                                        </td>
                                <td>
                                    <Button size="sm" onClick={() => enviarQuestionario("null")} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar</Button>
                                </td>
                            </tr>
                            {busca ?
                                questionariosFiltrados.map((questionario) => (
                                    <tr >
                                        <td className={styles.tbody}>
                                            {questionario.nome}
                                        </td>
                                        <td>
                                            <Button size="sm" onClick={() => enviarQuestionario(questionario.id)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar</Button>
                                        </td>
                                    </tr>
                                ))
                                :
                                questionarios.map((questionario) => (
                                    <tr >
                                        <td className={styles.tbody}>
                                            {questionario.nome}
                                        </td>
                                        <td>
                                            <Button size="sm" onClick={() => enviarQuestionario(questionario.id)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar</Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    {' '}
                    <Button size="sm" onClick={() => handleCancel()} className={styles.button}>
                        Cancelar
                        </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalQuestionariosComponent;