import React, { useState, useEffect } from "react";
import styles from "./ModalPacientesComponent.module.css";
import { Input, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';
import api from '../../service/api';
import { AiOutlineSearch, AiOutlineArrowRight } from 'react-icons/ai';
import ModalComponent from '../../components/Modal/ModalComponent';

function ModalPacientesComponent(props) {

    const [isOpen, setIsOpen] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const [pacientes, setPacientes] = useState([]);
    const [pacientesFiltrados, setPacientesFiltratos] = useState([]);
    const [busca, setBusca] = useState("");

    useEffect(() => {
        api.get('paciente').then(({ data }) => {
            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.nome < obj2.nome ? -1 :
                    (obj1.nome > obj2.nome ? 1 : 0);
            });
            setPacientes(dataSort);
        });
    }, []);

    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = pacientes.filter((paciente) => paciente.nome.toLocaleLowerCase().includes(lowerBusca));
        setPacientesFiltratos(filtrados);
        console.log(pacientesFiltrados);
    }, [busca]);

    const handleCancel = () => {
        setIsOpen(false);
        window.location.reload();
    }

    const enviarQuestionario = (id_paciente, telefone_paciente) => {
        api.post('envioQuestionario', {
            idPaciente: id_paciente,
            questionario: props.questionario
        }).then((response) => {
            // let url = "https://api.whatsapp.com/send?phone=55" +
            //     parseInt(telefone_paciente.replace(/[^0-9]/g, '')) +
            //     "&text=Link para responder o questionário https://healthb4.com.br/perguntas/" +
            //     response.data.linkCript;
            
            const baseUrl = process.env.REACT_APP_FRONT_URL;

            let url = "https://api.whatsapp.com/send?phone=55" +
                parseInt(telefone_paciente.replace(/[^0-9]/g, '')) +
                `&text=Link para responder o questionário ${baseUrl}/perguntas/${response.data.linkCript}`;
            
            window.open(url);
            setIsSent(true);
        });

    }

    return (
        <div>
            {isSent ? <ModalComponent mensagem="Questionário enviado com sucesso!" icon="SucessoEnvioQuestionario" link="home" /> : null}
            <Modal
                centered
                size=""
                isOpen={isOpen}
            >
                <ModalHeader>
                    Escolha um Paciente
                </ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <Input size="sm" placeholder="Busque pelo nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                        <InputGroupText className={styles.textBuscar}>
                            <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                    </InputGroup>
                    <Table size="sm" striped className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    Paciente
                            </th>
                                <th>
                                    Ações
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {busca ?
                                pacientesFiltrados.filter(p => p.status === "ativo").map((paciente) => (
                                    <tr >
                                        <td className={styles.tbody}>
                                            {paciente.nome}
                                        </td>
                                        <td>
                                            <Button size="sm" onClick={() => enviarQuestionario(paciente.id, paciente.telefone)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar</Button>
                                        </td>
                                    </tr>
                                ))
                                :
                                pacientes.filter(p => p.status === "ativo").map((paciente) => (
                                    <tr >
                                        <td className={styles.tbody}>
                                            {paciente.nome}
                                        </td>
                                        <td>
                                            <Button size="sm" onClick={() => enviarQuestionario(paciente.id, paciente.telefone)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar</Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    {' '}

                    <Button size="sm" onClick={() => handleCancel()} className={styles.button}>
                        Cancelar
                        </Button>


                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalPacientesComponent;